<template>
  <div>
    <HeaderPanel
      title="PO - Purchase Order"
      :hasDropdown="false"
      :filter="filter"
      placeholder="Search PO. Number"
      @search="getList"
      :hasFilter="false"
      routerPath="/setting/inventory/purchase-order/detail/0"
    >
    </HeaderPanel>

    <!-- </ul> -->

    <div class="bg-white border-red p-2 p-lg-3 mt-3">
      <b-tabs v-model="tabIndex">
        <b-tab title="All"></b-tab><b-tab title="Waiting for Approve"></b-tab>
        <b-tab title="Waiting for inspection"></b-tab>
        <b-tab title="Done"></b-tab><b-tab title="Cancel"></b-tab>
      </b-tabs>
      <b-row>
        <b-col>
          <b-table
            responsive
            striped
            hover
            :fields="fields"
            :items="items"
            :busy="isBusy"
            show-empty
            empty-text="No matching records found"
          >
            <template v-slot:cell(name)="{ item }">
              <router-link
                :to="'/setting/inventory/inventory-transfer/detail/' + item.id"
                ><span class="underline">{{ item.name }}</span></router-link
              >
            </template>
            <template v-slot:cell(create_date)="{ item }">
              <div>{{ item.create_date | moment($formatDateNew) }}</div>
              <div class="text-secondary">
                {{ item.create_date | moment("(HH:mm:ss)") }}
              </div>
            </template>
            <template v-slot:cell(status)="{ item }">
              <div :class="item.status == 1 ? 'text-success' : 'text-error'">
                {{ item.status == "1" ? "Active" : "InActive" }}
              </div>
            </template>
            <template v-slot:cell(action)="{ item }">
              <div class="d-flex justify-content-center">
                <router-link
                  :to="
                    '/setting/inventory/inventory-transfer/detail/' + item.id
                  "
                >
                  <b-button variant="link" class="text-warning px-1 py-0">
                    <font-awesome-icon icon="pencil-alt" title="Edit" />
                  </b-button>
                </router-link>
              </div>
            </template>
            <template v-slot:table-busy>
              <div class="text-center text-black my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong class="ml-2">Loading...</strong>
              </div>
            </template>
          </b-table>
        </b-col>
      </b-row>

      <Pagination
        @handleChangeTake="handleChangeTake"
        :filter="filter"
        :rows="rows"
        @pagination="pagination"
      />
    </div>
    <SideBarFilter
      ref="filter"
      :filter="filter"
      placeholder="Search Name"
      @searchAll="getList"
      @clearFilter="clearFilter"
    />

    <!-- <router-view></router-view> -->
    <!-- <FilterComponent ref="filter" :filter="filter" @submitFilter="getList" /> -->
  </div>
</template>
<script>
// import FilterComponent from "./SidebarFilter";

export default {
  name: "TransactionPending",
  components: {
    // FilterComponent,
    // HeaderPanel,
  },
  data() {
    return {
      filter: {
        search: "",
        status: null, // null = all,1 = active,0=inactive
        page: 1,
        take: 10,
      },
      isBusy: false,
      rows: 0,
      showing: 1,
      showingTo: 10,
      tabIndex: 0,
      fields: [
        {
          label: "Name",
          key: "name",
        },
        {
          label: "Code",
          key: "code",
        },
        {
          label: "Description",
          key: "description",
        },

        {
          label: "Create Date",
          key: "create_date",
        },

        {
          label: "Status",
          key: "status",
        },
        {
          label: "Action",
          key: "action",
        },
      ],
      items: [],
      data: {
        total: 0,
        total_success: 0,
        total_pending: 0,
      },
    };
  },
  created() {
    this.getList();
  },

  methods: {
    async getList() {
      let payload = { ...this.filter };
      if (typeof payload.status == "object" && payload.status) {
        if (payload.status.length > 1) payload.status = null;
        else if (payload.status.length == 1) payload.status = payload.status[0];
        else payload.status = null;
      }
      this.isBusy = true;
      const response = await this.axios.post(
        `/InventoryTransfer/List`,
        payload
      );

      this.rows = response.data.detail.total_count;
      this.items = response.data.detail.inventory_transfer;
      this.isBusy = false;
    },

    handleChangeTake(value) {
      this.filter.page = 1;
      this.filter.take = value;
      this.getList();
    },
    pagination(page) {
      this.filter.page = page;
      this.getList();
    },
    openFilter() {
      this.$refs.filter.show();
    },
    selectBranch() {},
    clearFilter() {
      this.filter = {
        search: "",
        status: 1, // null = all,1 = active,0=inactive
        page: 1,
        take: 10,
      };
      this.getList();
    },
  },
};
</script>
<style lang="scss">
.banner-image {
  max-width: 50px;
  height: auto;
  width: 100%;
  aspect-ratio: 1;
}
</style>
