<template>
  <div>
    <div>
      <h1 class="mr-sm-4 header-tablepage">Branch Group</h1>
    </div>
    <div class="title-tabs mt-3">Branch Group</div>
    <b-container class="no-gutters bg-white">
      <div class="pt-3">
        <b-row>
          <b-col lg="6" md="6" sm="12" xs="12">
            <InputText
              textFloat="Name"
              placeholder="Name"
              type="text"
              name="branch_name_en"
              v-model="form.name"
              :isValidate="$v.form.name.$error"
              :v="$v.form.name"
              isRequired
            />
          </b-col>

          <b-col lg="6" md="6" sm="12" xs="12">
            <InputText
              textFloat="Sort Order"
              placeholder="Sort Order"
              type="number"
              name="sort_order"
              v-model="form.sort_order"
            />
          </b-col>
        </b-row>
      </div>
    </b-container>
    <div class="title-tabs mt-3">Branch</div>
    <b-container class="no-gutters bg-white">
      <div class="pt-3">
        <b-row class="justify-content-between align-items-center">
          <b-col lg="5" md="6" sm="6" xs="10" cols="10">
            <b-input-group>
              <b-form-input
                class="search-bar"
                @keyup.enter="handleSearch($event)"
                @input="handleSearch($event)"
                placeholder="Search Branch, Plant ID"
                v-model="filter.search"
              >
              </b-form-input>
              <b-input-group-append is-text>
                <b-iconstack font-scale="2" type="submit">
                  <b-icon
                    stacked
                    icon="search"
                    scale="0.5"
                    variant="grey"
                  ></b-icon>
                </b-iconstack>
              </b-input-group-append> </b-input-group
          ></b-col>
          <b-col
            xl="2"
            lg="3"
            md="3"
            sm="6"
            xs="2"
            cols="2"
            class="d-flex justify-content-end"
          >
            <button
              type="button"
              class="btn button btn-mobile btn-block"
              id="open-modal-branch"
              @click="openModalBranch()"
            >
              <font-awesome-icon icon="plus" class="text-black d-sm-none" />
              <span class="d-none d-sm-block">
                <font-awesome-icon icon="plus-square" class="pointer" />
                Add Branch
              </span>
            </button>
          </b-col>
        </b-row>
        <div class="pb-3 mt-3">
          <b-row>
            <b-col>
              <b-table
                responsive
                striped
                hover
                :per-page="filter.take"
                :current-page="filter.page"
                :fields="fields"
                :items="items"
                :busy="isBusy"
                show-empty
                empty-text="No matching records found"
                id="table-show-staff"
              >
                <template v-slot:cell(active)="{ item }">
                  <span :class="item.active ? 'text-success' : 'text-error'">{{
                    item
                  }}</span>
                </template>

                <template v-slot:table-busy>
                  <div class="text-center text-black my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong class="ml-2">Loading...</strong>
                  </div>
                </template>
              </b-table>
            </b-col>
          </b-row>

          <Pagination
            :pageOptions="pageOptions"
            :filter="filter"
            :rows="rows"
          />
          <!-- @handleChangeTake="handleChangeTake" -->
          <!-- @pagination="pagination" -->
          <div class="text-error" v-if="branchError">
            Please Select Branch At Least 1.
          </div>
        </div>
      </div>
    </b-container>
    <ModalSelectBranch
      ref="ModalSelectBranch"
      @selectBranch="selectBranch"
      :list="branchList"
      id="1"
    />

    <FooterAction
      routePath="/setting/branch-setting/branch-group"
      @submit="save()"
    />
  </div>
</template>
<script>
import { required, requiredIf } from "vuelidate/lib/validators";
import ModalSelectBranch from "@/components/staff/ModalSelectBranch";
export default {
  components: {
    ModalSelectBranch,
  },
  data() {
    return {
      branchList: [],

      isLoading: true,
      filter: {
        search: "",
        page: 1,
        take: 10,
      },
      pageOptions: [
        { value: 10, text: "10 / page" },
        { value: 30, text: "30 / page" },
        { value: 50, text: "50 / page" },
        { value: 100, text: "100 / page" },
      ],
      fields: [
        {
          key: "plant_id",
          label: "Plant ID",
          tdClass: "w-25",
        },
        {
          key: "name",
          label: "Branch Name",
          tdClass: "w-25",
        },
        {
          key: "branch_group",
          label: "Branch Group",
          tdClass: "w-25",
        },

        {
          key: "branch_type",
          label: "Type",
          tdClass: "w-25",
        },
      ],
      showBranch: [],
      isBusy: false,
      rows: 0,
      showingTo: 10,
      form: {
        id: Number(this.$route.params.id),
        name: "",
        sort_order: "",
        add_branch_id: [],
        delete_branch_id: [],
        branch_id: [],
        branch: [],
        branch_total: [],
      },
      items: [],
      delete_branch_id: [],
      branchError: false,
    };
  },
  validations: {
    form: {
      name: { required },
    },
  },
  async created() {
    this.isLoading = true;
    if (this.form.id != 0) return await this.getDetail();
    this.getBranchList();
  },
  methods: {
    filterPage(filter) {
      this.filter = filter;
      this.getDetail();
    },
    async getDetail() {
      const response = await this.axios.post(
        `/Setting/GetBranchGroup/${this.form.id}`,
        this.filter
      );
      if (this.form.id != 0) {
        this.form = response.data.detail;
      }
      this.getBranchList();
    },
    selectBranch(val) {
      var branchSelect = [];
      for (let branchs of this.branchList) {
        for (let branch of branchs.branchList) {
          if (val.includes(branch.id)) {
            branch.is_check = true;
            branch.branch_group = branchs.name;
            branchSelect.push(branch);
          } else {
            branch.is_check = false;
          }
        }
      }
      var index = 0;
      for (let deleteValue of this.delete_branch_id) {
        if (val.includes(deleteValue)) this.delete_branch_id.splice(index, 1);
        index++;
      }

      const add = val.filter(
        (el) => !this.form.branch.find((els) => el == els.id)
      );
      const deleteID = this.form.branch.filter((i) => !val.includes(i));

      this.items = branchSelect;
      this.showBranch = branchSelect;
      this.form.add_branch_id = add;

      this.delete_branch_id = deleteID;
      this.form.branch = branchSelect.map((el) => el.id);
      this.form.branch_id = branchSelect.map((el) => el.id);
      this.rows = val.length;
      this.branchError = false;
    },
    openModalBranch() {
      this.$refs.ModalSelectBranch.show();
    },
    async getBranchList() {
      const result = await this.axios(`/branch/group`);

      var branchSelect = [];
      // if (this.form.branch.length > 0)
      for (let branch of result.data.detail) {
        branch.selected_count = 0;
        for (const check of branch.branchList) {
          if (this.form.branch_total.find((el) => el.id == check.id)) {
            branch.selected_count += 1;
            check.branch_group = branch.name;
            check.is_check = true;
            branchSelect.push(check);
          }
        }
      }

      this.rows = this.form.branch_count;
      this.branchList = result.data.detail;
      this.items = branchSelect;
      this.showBranch = branchSelect;
      this.isLoading = false;
    },

    handleChangeTake(value) {
      this.filter.page = 1;
      this.filter.take = value;
      this.getDetail();
    },
    handleSearch(val) {
      this.filter.search = val;
      this.getDetail();
    },
    pagination(page) {
      this.filter.page = page;
      this.getDetail();
    },
    async save() {
      this.branchError = false;
      this.$v.form.$touch();
      if (this.$v.form.$error) {
        return;
      }
      let payload = { ...this.form };

      delete payload.add_branch_id;
      delete payload.branch;
      var res;
      if (payload.sort_order == "") {
        payload.sort_order = 0;
      }

      if (this.form.branch.length == 0) {
        return (this.branchError = true);
      }
      this.$bus.$emit("showLoading");
      if (this.form.id == 0) {
        res = await this.axios.post(`/Setting/CreateBranchGroup`, payload);
      } else {
        res = await this.axios.put(`/Setting/UpdateBranchGroup`, payload);
      }
      this.$bus.$emit("hideLoading");
      if (res.data.result == 1) {
        this.successAlert().then(() => {
          this.$router.push("/setting/branch-setting/branch-group");
        });
      } else this.errorAlert(res.data.message);
    },
  },
};
</script>
