<template>
  <div>
    <div v-if="isLoading">
      <OtherLoading />
    </div>
    <div v-else>
      <b-form>
        <h1 class="mr-sm-4 header-tablepage">Branch Management Detail</h1>

        <b-tabs class="mt-3" v-model="tabIndex">
          <b-tab title="Branch Info">
            <BranchInfo
              :BranchOptions="BranchOptions"
              :BranchTierOptions="BranchTierOptions"
              :BranchStyleOptions="BranchStyleOptions"
              :BranchTypeOptions="BranchTypeOptions"
              :form="form"
              :mode="mode"
              :v="$v"
              @saveForm="saveForm"
              :type="typeOptions"
            />
          </b-tab>
          <b-tab title="Staff Info">
            <StaffPanel
              ref="staff"
              @setStaffID="setStaffID"
              :sales_id="form.sales_id"
              :filter="filter"
              :staffObj="staffList"
              @filterPage="filterPage"
              @getStaff="getStaffList"
            />
          </b-tab>
          <b-tab title="Member Tier Point" v-if="mode">
            <Point
              :form="form"
              :filter="filter"
              :v="$v"
              :originBranchPoint="originBranchPoint"
            />
          </b-tab>
          <b-tab title="Setting" v-if="mode">
            <InactiveUser />
          </b-tab>
        </b-tabs>
        <div class="no-gutters bg-white mt-3 py-2 px-3">
          <b-form-checkbox
            switch
            v-model="form.active"
            class="radio-active"
            size="lg"
            :value="1"
            :unchecked-value="0"
          >
            <span class="ml-2 main-label">{{
              form.active ? "Active" : "Inactive"
            }}</span>
          </b-form-checkbox>
        </div>
        <FooterAction routePath="/branch-management" @submit="saveForm()" />
      </b-form>

      <ModalLoading ref="modalLoading" :hasClose="false" />
    </div>
  </div>
</template>

<script>
import {
  required,
  minLength,
  email,
  requiredIf,
} from "vuelidate/lib/validators";
import OtherLoading from "@/components/loading/OtherLoading";
import ModalLoading from "@/components/modal/ModalLoading";
import StaffPanel from "./components/StaffPanel";
import BranchInfo from "./components/BranchInfo";
import InactiveUser from "./components/InactiveUser";
import Point from "./components/Point";
const eng_number = (val) => {
  if (val.match("[a-zA-Z\s0-9-_#{}]+$")) {
    return true;
  }
  return false;
};
function hasWhiteSpace(s) {
  if (/\s/g.test(s)) {
    return false;
  }
  return true;
}
const thai_number = (val) => {
  if (val) {
    if (val.match("^[ก-๏\s0-9-_#{} ]+$")) {
      return true;
    }
  }
  return false;
};
export default {
  components: {
    ModalLoading,
    OtherLoading,
    StaffPanel,
    BranchInfo,
    Point,
    InactiveUser,
  },
  validations() {
    var form = {
      email: { required, email },
      name: { required, thai_number },
      name_en: { required, eng_number },
      short_name: { required },
      sales_channel: { required },
      store_code: { required, eng_number, hasWhiteSpace },
      plant_id: { required },
      plant_ref_number: { required },
      branch_type_id: { required },
      branch_group_template_id: { required },
      branch_point: {
        $each: {
          baht_per_point: {
            required: requiredIf(function () {
              return this.form.is_point == 1;
            }),
          },
        },
      },
    };
    if (!this.mode) {
      form.password = { required, minLength: minLength(6) };
    }

    return { form };
  },

  computed: {
    mode() {
      return this.$route.params.id != 0;
    },
  },
  watch: {
    tabIndex(val) {
      console.log(val);
      if (val == 1) this.getStaffList();
    },
  },
  data() {
    return {
      tabIndex: 0,
      fields: [
        {
          key: "plant_id",
          label: "Plant ID",
        },
        {
          key: "short_name",
          label: "Branch Name",
        },
        {
          key: "branch_group",
          label: "Branch Group",
        },

        {
          key: "branch_type",
          label: "Type",
        },
        {
          key: "actions",
          label: "Action",
        },
      ],
      pageOptions: [
        { value: 10, text: "10 / page" },
        { value: 30, text: "30 / page" },
        { value: 50, text: "50 / page" },
        { value: 100, text: "100 / page" },
      ],
      filter: {
        branch_id: "",
        search: "",
        page: 1,
        take: 10,
        add_sales_id: [],
        delete_sales_id: [],
      },
      isBusy: false,
      rows: 0,
      items: [],
      isLoading: false,

      form: {
        email: "",
        password: "",
        name: "",
        name_en: "",
        short_name: "",
        active: 1,
        allow_time_change: 1,
        is_point: 0,
        branch_point: [],
        type: [],
        store_code: "",
        plant_id: "",
        plant_ref_number: "",
        branch_tier_id: 0,
        branch_tier_name: "",
        branch_style_id: 0,
        branch_style_name: "",
        branch_type_id: null,
        branch_group_template_id: null,
        telephone: "",
        open_day: "",
        open_time: "",
        close_time: "",
        address: "",
        subdistrict: "",
        district: "",
        province: "",
        zip_code: "",
        add_sales_channel: [],
        add_sales_id: [],
        sales_id: [],
        delete_sales_channel: [],
        delete_sales_id: [],
        latitude: "",
        longitude: "",
      },
      delete_branch_id: [],
      isDisable: true,
      id: this.$route.params.id,
      sales_channel_freeze: [],
      BranchOptions: [],
      BranchStyleOptions: [],
      BranchTierOptions: [],
      typeOptions: [],
      BranchTypeOptions: [],
      staffList: [],
      originBranchPoint: [],
    };
  },
  async created() {
    this.isLoading = true;
    this.getOption();
    if (this.mode) {
      this.getBranchID();
    }
  },
  methods: {
    async getStaffList() {
      if (this.tabIndex != 1) return;

      this.$refs.staff.isBusy = true;
      var payload = {
        branch_id: this.$route.params.id,
        search: this.filter.search,
        page: this.filter.page,
        take: this.filter.take,
        add_sales_id: this.form.add_sales_id,
        delete_sales_id: this.form.delete_sales_id,
      };
      const { data } = await this.axios.post(`/branch/salesperson`, payload);

      this.$refs.staff.isBusy = false;
      this.staffList = data.detail;
    },
    async getBranchID() {
      const { data } = await this.axios(`/branch/${this.$route.params.id}`);
      this.sales_channel_freeze = data.detail.sales_channel;
      if (data.detail.branch_point.length == 0)
        data.detail.branch_point = data.detail.branch_origin_point;
      else
        data.detail.branch_point = [
          ...data.detail.branch_origin_point.filter(
            (el) =>
              !data.detail.branch_point.find(
                (ele) => el.member_level_id == ele.member_level_id
              )
          ),
          ...data.detail.branch_point,
        ].sort((a, b) => a.member_level_id - b.member_level_id);
      this.originBranchPoint = JSON.parse(
        JSON.stringify(data.detail.branch_origin_point)
      );
      this.form = data.detail;
      this.getStaffList();
    },
    setStaffID(val) {
      var add_id = null;
      var delete_id = null;
      if (this.staffList.data) {
        add_id = val.filter(
          (el) => this.staffList.data.findIndex((el1) => el1.id == el) == -1
        );
        delete_id = this.staffList.data.filter((el) => !val.includes(el.id));
      }

      this.form.add_sales_id = add_id || val;
      this.form.delete_sales_id = delete_id ? delete_id.map((el) => el.id) : [];
      this.getStaffList();
    },
    async getOption() {
      var { data } = await this.axios(`/branch/groupTemplate`);
      this.BranchOptions = data.detail;
      var { data } = await this.axios(`/branch/GetBranchTierList`);
      this.BranchTierOptions = data.detail;
      var { data } = await this.axios(`/branch/GetBranchStyleList`);
      this.BranchStyleOptions = data.detail;
      var { data } = await this.axios(`/branch/type`);
      this.BranchTypeOptions = data.detail;
      var { data } = await this.axios(`/Branch/salesChannel`);
      this.typeOptions = data.detail;
      this.isLoading = false;
    },
    handleStaff(e) {
      let charCode = e.keyCode;
      if (charCode == 32) return e.preventDefault();
    },

    async saveForm() {
      this.$v.form.$touch();
      this.form.add_sales_channel = this.form.sales_channel.filter(
        (el) => !this.sales_channel_freeze.includes(el)
      );
      this.form.delete_sales_channel = this.sales_channel_freeze.filter(
        (el) => !this.form.sales_channel.includes(el)
      );
      if (this.$v.form.$error) {
        return;
      }
      this.$refs.modalLoading.show();
      if (this.$moment(this.form.open_time).format("HH:mm") == "Invalid date") {
        this.form.open_time = this.form.open_time
          ? this.$moment(this.form.open_time, "HH:mm:ss").format("HH:mm")
          : "";
      } else {
        this.form.open_time = this.form.open_time
          ? this.$moment(this.form.open_time).format("HH:mm")
          : "";
      }
      if (
        this.$moment(this.form.close_time).format("HH:mm") == "Invalid date"
      ) {
        this.form.close_time = this.form.close_time
          ? this.$moment(this.form.close_time, "HH:mm:ss").format("HH:mm")
          : "";
      } else {
        this.form.close_time = this.form.close_time
          ? this.$moment(this.form.close_time).format("HH:mm")
          : "";
      }
      this.form.delete_branch_id = this.delete_branch_id;
      if (this.mode) {
        this.axios
          .put(`/branch`, this.form)
          .then((res) => {
            this.$refs.modalLoading.hide();
            if (res.data.result) {
              this.successAlert().then(() =>
                this.$router.push("/branch-management")
              );
            } else {
              this.errorAlert(res.data.message);
            }
          })
          .catch((err) => {
            console.log(err);
            this.errorAlert(err.message, "ERROR");
          });
      } else {
        this.axios
          .post(`/branch`, this.form)
          .then((res) => {
            this.$refs.modalLoading.hide();
            if (res.data.result) {
              this.successAlert().then(() =>
                this.$router.push("/branch-management")
              );
            } else {
              this.errorAlert(res.data.message);
            }
          })
          .catch((err) => {
            this.errorAlert(err.message, "ERROR");
          });
      }
    },

    filterPage(filter) {
      this.filter = { ...this.filter, ...filter };
      this.getStaffList();
    },
  },
};
</script>

<style lang="scss" scoped>
.img-box-preview {
  width: 100%;
  padding-bottom: 50%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  align-items: center;
  border: 2px dashed #bfbfbf;
  position: relative;
}
.reset-password {
  text-decoration: underline;
  color: blue;
  cursor: pointer;
  font-size: 12px;
}
::v-deep .input-group-text {
  background-color: #fff;
  padding: 0 0.75rem;
}
::v-deep .nav-tabs .nav-link:not(.active):hover {
  background-color: transparent;
  color: var(--primary-color);
  border-bottom: 4px solid var(--primary-color);
  border-top-color: transparent;
  border-right-color: transparent;
  border-left-color: transparent;
}
::v-deep .nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  background-color: transparent !important;
  color: var(--primary-color) !important;
  border-bottom: 4px solid var(--primary-color) !important;
}
</style>
