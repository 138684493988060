<template>
  <div>
    <div>
      <h1 class="mr-sm-4 header-tablepage">PO - Purchase Order</h1>
    </div>
    <b-tabs class="mt-3">
      <b-tab title="Detail">
        <div class="bg-white">
          <div class="title-tabs">Detail</div>
          <div class="p-3">
            <b-row>
              <b-col cols="12" md="6">
                <InputText
                  placeholder="Invoice No."
                  textFloat="Invoice No."
                  disabled
                />
              </b-col>
              <b-col cols="12" md="6">
                <InputSelect placeholder="manufacturer" title="Manufacturer" v>
                  <template v-slot:option-first>
                    <b-form-select-option :value="null" disabled
                      >-- Select Manufacturer --</b-form-select-option
                    >
                  </template>
                </InputSelect>
              </b-col>
              <b-col cols="12" md="6">
                <InputSelect placeholder="manufacturer" title="Branch Order" v>
                  <template v-slot:option-first>
                    <b-form-select-option :value="null" disabled
                      >-- Select Branch --</b-form-select-option
                    >
                  </template>
                </InputSelect>
              </b-col>
              <b-col cols="12" md="6">
                <InputText
                  placeholder="Invoice No."
                  textFloat="Invoice No."
                  disabled
                />
              </b-col>
              <b-col cols="12" md="6">
                <InputDatePickerFilter
                  textFloat="Created Date (DD/MM/YYYY)"
                  name="valid-form"
                  ref="valid-form"
                  format="dd/MM/yyyy (HH:mm)"
                  type="datetime"
                  :isRequired="true"
                />
              </b-col>
              <b-col cols="12" md="6">
                <InputDatePickerFilter
                  textFloat="Delivery Schedule (DD/MM/YYYY)"
                  name="valid-form"
                  ref="valid-form"
                  format="dd/MM/yyyy (HH:mm)"
                  type="datetime"
                />
              </b-col>
              <b-col cols="12">
                <div>
                  <div class="title-tabs my-3">Product List</div>
                  <!-- Select Product -->

                  <div>
                    <b-button
                      class="btn-select-branch"
                      @click.prevent="selectProduct"
                    >
                      Select Product
                      <font-awesome-icon
                        icon="chevron-right"
                        class="ft-14 ml-2"
                      />
                    </b-button>
                    <!-- <div class="break"></div> -->
                    <span class="branch-select">
                      (Selected Product 0 List)</span
                    >
                  </div>
                  <SelectProductList
                    :fieldProductSelect="[]"
                    :items="[]"
                    :rows="rows"
                    :filter="filter"
                    :id="0"
                    @deleteSelectProduct="deleteSelectProduct"
                  />
                </div>
              </b-col>
              <b-col cols="12">
                <InputTextArea
                  textFloat="Note"
                  type="text"
                  class="mt-0 custom-input"
                  placeholder="Provide Additional Information"
                  rows="3"
                  name="message"
              /></b-col>
            </b-row>
          </div>
        </div>

        <FooterAction
          @submit="checkForm(0)"
          routePath="/setting/inventory/purchase-order"
        />
      </b-tab>
      <b-tab title="History"> </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import SelectProductList from "@/components/coupon/detail/SelectProductList";
export default {
  components: { SelectProductList },
};
</script>

<style></style>
