const InventoryTransfer = {
  path: "",
  name: "",
  redirect: "inventory-transfer",
  component: {
    render(c) {
      return c("router-view");
    },
  },
  children: [
    {
      path: "inventory-transfer",
      redirect: "inventory-transfer",
      component: {
        render(c) {
          return c("router-view");
        },
      },
      name: "Inventory Transfer",
      meta: {
        requiresAuth: true,
        activeUrl: "/inventory-transfer",
        parent: "/inventory-transfer",

        lv: 1,
      },
      children: [
        {
          path: "",
          name: "",
          component: () => import("@/views/pages/inventoryTransfer"),
          meta: {
            requiresAuth: true,
            activeUrl: "/inventory-transfer",
            parent: "/inventory-transfer",

            lv: 2,
          },
        },
        {
          path: "detail/:id",
          name: "Inventory Transfer Detail",
          component: () => import("@/views/pages/inventoryTransfer/detail"),
          meta: {
            requiresAuth: true,
            isChildren: true,
            activeUrl: "/inventory-transfer",
            parent: "/inventory-transfer",

            lv: 3,
          },
        },
      ],
    },
  ],
  meta: { requiresAuth: true },
};
const PurchaseOrder = {
  path: "",
  name: "",
  redirect: "purchase-order",
  component: {
    render(c) {
      return c("router-view");
    },
  },
  children: [
    {
      path: "purchase-order",
      redirect: "purchase-order",
      component: {
        render(c) {
          return c("router-view");
        },
      },
      name: "Purchase Order",
      meta: {
        requiresAuth: true,
        activeUrl: "/purchase-order",
        parent: "/purchase-order",

        lv: 1,
      },
      children: [
        {
          path: "",
          name: "",
          component: () => import("@/views/pages/purchaseOrder"),
          meta: {
            requiresAuth: true,
            activeUrl: "/purchase-order",
            parent: "/purchase-order",

            lv: 2,
          },
        },
        {
          path: "detail/:id",
          name: "Purchase Order Detail",
          component: () => import("@/views/pages/purchaseOrder/detail"),
          meta: {
            requiresAuth: true,
            isChildren: true,
            activeUrl: "/purchase-order",
            parent: "/purchase-order",

            lv: 3,
          },
        },
      ],
    },
  ],
  meta: { requiresAuth: true },
};
const Inventory = {
  path: "",
  name: "",
  redirect: "inventory",
  component: {
    render(c) {
      return c("router-view");
    },
  },
  meta: {
    requiresAuth: true,
    activeUrl: "/setting",
    isChildren: true,
    lv: 2,
  },
  children: [
    {
      path: "inventory",
      name: "Inventory",
      redirect: "inventory",
      component: {
        render(c) {
          return c("router-view");
        },
      },
      meta: {
        requiresAuth: true,
        activeUrl: "/setting",
        isChildren: true,
        lv: 2,
      },
      children: [
        {
          path: "",
          name: "",
          component: () =>
            import("@/views/pages/setting/settingPage/Inventory"),
          meta: {
            requiresAuth: true,
            activeUrl: "/setting",
            isChildren: true,
            lv: 3,
          },
        },

        // {
        //   path: "",
        //   name: "",
        //   redirect: "template",
        //   component: {
        //     render(c) {
        //       return c("router-view");
        //     },
        //   },
        //   meta: {
        //     requiresAuth: true,
        //     activeUrl: "/setting",
        //     isChildren: true,
        //     lv: 2,
        //   },
        //   children: [
        //     {
        //       path: "template",
        //       name: "Ticket Template",
        //       redirect: "template",
        //       component: {
        //         render(c) {
        //           return c("router-view");
        //         },
        //       },
        //       meta: {
        //         requiresAuth: true,
        //         activeUrl: "/setting",
        //         isChildren: true,
        //         lv: 2,
        //       },
        //       children: [
        //         {
        //           path: "",
        //           name: "",
        //           component: () =>
        //             import(
        //               "@/views/pages/setting/settingPage/Ticket/Template/index.vue"
        //             ),
        //           meta: {
        //             requiresAuth: true,
        //             activeUrl: "/setting",
        //             isChildren: true,
        //             lv: 3,
        //           },
        //         },
        //         {
        //           path: ":id",
        //           name: "Ticket Template Detail",
        //           redirect: "/setting/template",
        //           component: {
        //             render(c) {
        //               return c("router-view");
        //             },
        //           },
        //           meta: {
        //             requiresAuth: true,

        //             activeUrl: "/setting",
        //             isChildren: true,
        //             lv: 2,
        //           },
        //           children: [
        //             {
        //               path: "",
        //               name: "",
        //               component: () =>
        //                 import(
        //                   "@/views/pages/setting/settingPage/Ticket/Template/detail.vue"
        //                 ),
        //               meta: {
        //                 requiresAuth: true,

        //                 activeUrl: "/setting",
        //                 isChildren: true,
        //                 lv: 2,
        //               },
        //             },
        //             // {
        //           ],
        //         },
        //       ],
        //     },
        //   ],
        // },
        InventoryTransfer,
        PurchaseOrder,
      ],
    },
  ],
};
export default Inventory;
